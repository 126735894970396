body,
#root {
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
	-webkit-overflow-scrolling: touch;
	font-family: system-ui, "Roboto", "Helvetica", "Arial", sans-serif !important;
}

body {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll;
	overflow-x: hidden;
}

body::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #ededed #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-track {
	background: rgb(17, 17, 17);
	padding: 2px;
}

*::-webkit-scrollbar-thumb {
	background-color: #ededed;
	border-radius: 2px;
	border: 0px solid #ffffff;
}

@keyframes appearAnimation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.hover-style:hover {
	color: #ff6600 !important;
}

.hover-style-link-orange:hover {
	color: #ff6600 !important;
	text-decoration: underline;
}

.hover-style-link:hover {
	text-decoration: underline;
}

.hover-style-white:hover {
	color: #ffffff !important;
}

.hover-style-images:hover {
	border: 2px solid #ff6600!important;
}

.ql-toolbar.ql-snow{
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border: 1px solid grey!important;

}

.ql-container.ql-snow {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid grey!important;
	border-top: none!important;
}
